import "./footer.css"
import React from "react"
import { Link } from "@reach/router"

export default ({ children }) => {
  return (
    <div className="d-flex justify-content-center align-items-center mc-footer align-self-stretch">
      <div className={"text-center"}>
        <p>© Copyright 2020 <b>Cashback·Medical</b>. All rights reserved.</p>
        <p><Link style={{color: "white"}} to={"/privacy"}>Privacy Policy</Link></p>
        <p><Link style={{color: "white"}} to={"/terms-of-service"}>Terms of Service</Link></p>
      </div>
    </div>
  )
}