import React from "react"
import Container from './container'
import "./layout.css"
import Header from './header'
import Footer from './footer'
import {isMobile} from "react-device-detect"

export default ({ children }) => {
  return (
    <Container className="d-flex flex-row">
      <Header isMobile={isMobile}/>
      {children}
      <Footer className="align-self-end"/>
    </Container>
  )
}