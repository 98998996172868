import "./header.css"
import React from "react"
import { Link } from "gatsby"
import DropdownIcon from '../../static/menu_FILL0_wght400_GRAD0_opsz48.svg';
// import CallIconWhite from '../../static/call_FILL0_wght400_GRAD0_opsz48-white.svg';
import Dropdown from 'react-bootstrap/Dropdown';

export default ({isMobile }) => {
  if (isMobile) {
    return (
      <MobileMenu />
    )
  } else {
    return (
      <WebMenu />
    )
  }
}

const MobileMenu = () => {
  return (
<div className={"mc-header-wrapper"}>
    <div className="d-flex justify-content-between align-items-center mc-header">
      <div>
        <div style={{marginBottom:"5px"}} className="accent-div"/>
        <Link to={"/"}><h2 style={{color: "white", fontFamily:"JarkartaDisplayMedium"}}>Cb·Medical</h2></Link>
        {/*<Link to="/"><img className="img-logo" src={"/logo_medicash_white.png"} alt={"logo"}/></Link>*/}
      </div>

      {/*<div className="dropdown nav-spacing">*/}
      {/*  <div className="dropdown-toggle" role="button"*/}
      {/*       data-toggle="dropdown" aria-expanded="false">*/}
      {/*    <img src={DropdownIcon} alt="dropdown" />*/}
      {/*  </div>*/}
      {/*  <div className="dropdown-menu dropdown-menu-right text-right">*/}

      {/*    <div className={"dropdown-item d-flex align-items-center"}>*/}

      {/*    </div>*/}
      {/*    <a className="dropdown-item" style={{color:"inherit"}} href={process.env.GATSBY_USER_LOGIN}>Patient login  ›</a>*/}
      {/*    <a className="dropdown-item" style={{color:"inherit"}} href={process.env.GATSBY_PROVIDER_PORTAL}>Provider portal  ›</a>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Dropdown>
        <Dropdown.Toggle as="div" variant="success" id="dropdown-basic">
          <img src={DropdownIcon} alt="dropdown" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href={"/faq"}>FAQs</Dropdown.Item>
          <Dropdown.Item href={"/medical-collections"}>Medical collections</Dropdown.Item>
          {/*<Dropdown.Item href={process.env.GATSBY_USER_LOGIN}>User login</Dropdown.Item>*/}
          {/*<Dropdown.Item href={process.env.GATSBY_PROVIDER_PORTAL}>Provider portal</Dropdown.Item>*/}
          <Dropdown.Item className={"d-flex align-items-center"} href={"tel:407-259-2969"}>
            <div>
              <p style={{color:"inherit", margin: "0"}}>(407) 259-2969</p>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
</div>
  )
}

const WebMenu = () => {
  return (
    <div className={"mc-header-wrapper"}>
    <div className="d-flex justify-content-between align-items-center mc-header">
      <div>
        <div style={{marginBottom:"5px"}} className="accent-div"/>
        <Link to={"/"}><h2 style={{color: "white", fontFamily:"JarkartaDisplayMedium"}}>Cb·Medical</h2></Link>
        {/*<Link to="/"><img className="img-logo" src={"/logo_medicash_white.png"} alt={"logo"}/></Link>*/}
      </div>
      <div className={"d-flex align-items-center"}>
        {/*<button className={"btn btn-primary nav-spacing"}><a style={{color:"inherit"}} href={process.env.GATSBY_USER_LOGIN}>Patient login  ›</a></button>*/}
        <div className={"nav-spacing d-flex align-items-center"}>
          <div>
            <p style={{margin:"0"}}><a style={{color:"white"}} href="tel:407-259-2969">(407) 259-2969</a></p>
          </div>
        </div>
        <div className="dropdown nav-spacing">
          <div className="dropdown-toggle" type="button" id="dropdownMenuButton"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={DropdownIcon} alt="dropdown" />
          </div>
          <div className="dropdown-menu dropdown-menu-right text-right" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item" to={"/faq"}>FAQs</Link>
            <Link className="dropdown-item" to={"/medical-collections"}>Medical collections</Link>
            {/*<a className="dropdown-item" style={{color:"inherit"}} href={process.env.GATSBY_PROVIDER_PORTAL}>Provider portal</a>*/}
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}